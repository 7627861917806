<template>
  <div class="container mx-auto mt-4">
    <main-navigation v-if="currentUser" class="ml-4" />
    
    <h1 class="text-center text-5xl font-bold">
      <span class="text-brand-red">M</span><span class="text-brand-green">excellent</span>
    </h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainNavigation from './MainNavigation.vue';

export default {
  components: { MainNavigation },

  computed: {
    ...mapGetters(['currentUser']),
  },
}
</script>

<style>

</style>