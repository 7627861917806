import * as types from "@/store/mutations";

const state = {
  notifications: []
};

export const getters = {
  notifications: notificationsState => notificationsState.notifications
};

export const actions = {
  /*
  notification : {
    text : String,
    type : String 'success' || 'error'
  }
  */
  pushNotifications({ commit }, notifications) {
    if (typeof notifications === "object") notifications = [notifications];
    
    commit(types.UPDATE_NOTIFICATIONS, notifications);

    setTimeout(() => {
      commit(types.CLEAR_NOTIFICATIONS);
    }, 250);
  }
};

export const mutations = {
  [types.UPDATE_NOTIFICATIONS](notificationsState, notifications) {
    notificationsState.notifications = notifications;
  },
  [types.CLEAR_NOTIFICATIONS](notificationsState) {
    notificationsState.notifications = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};