import { createStore } from 'vuex';
import VuexPersistence from "vuex-persist";

import categories from "./modules/categories";
import notifications from "./modules/notifications";
import session from "./modules/session";

import apiService from "./api/api.service";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [
    "categories",
    "notifications",
    "session",
  ]
});

export default createStore({
  modules: {
    categories,
    notifications,
    session,
    
    apiService
  },
  plugins: [vuexLocal.plugin]
});
