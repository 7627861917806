<template>
  <div class="px-6 py-8 max-w-sm mx-auto">
    <form @keyup.enter="login">
      <pocket-input 
        type="text" 
        id="email" 
        autocomplete="email" 
        pocketSize="14"
        v-model="authForm.email"
      >
        E-mail
      </pocket-input>
      <pocket-input 
        type="password" 
        id="password"
        autocomplete="password"
        pocketSize="20"
        v-model="authForm.password" 
      >
        Password
      </pocket-input>
    </form>

    <button class="app-button app-button--primary w-full" @click="login">
      Login
    </button>
  </div>
</template>

<script>
import PocketInput from '@/components/PocketInput.vue';

import Form from '@/utils/classes/Form';

export default {
  components: { PocketInput },

  data() {
    return {
      authForm: new Form({
        email: null,
        password: null,
      })
    }
  },

  mounted() {
    this.$store.dispatch("getCsrfCookie");
  },

  methods: {
    login() {
      if (!this.authForm.allKeysAreDirty) {
        return this.$toast.warning("Please fill both fields.");
      }

      this.$store.dispatch("login", this.authForm.data)
        .then(user => {
          switch (user.user_role.name) {
            case 'mexcellent_employee':
              this.$router.push({ name: 'form' });
              break;
            case 'admin':
              this.$router.push({ name: 'admin' });
              break;
          }
        })
        .catch(() => {
          this.authForm.password = null;
        })
    }
  }
}
</script>

<style>

</style>