// Categories
export const SET_CATEGORIES = "SET_CATEGORIES";

// Notifications
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

// Session
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ROLES = "SET_ROLES";
export const SET_STAFF = "SET_STAFF";
export const LOGOUT = "LOGOUT";