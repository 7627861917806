import store from "@/store";
import axios from "axios";

export const setRequestInterceptor = () => {
    axios.interceptors.request.use(
        request => {
            request.headers.Authorization = store.getters.token;
            request.headers.Accept = "application/json";
            request.headers['Content-Type'] = "application/json";

            return request;
        },
        error => Promise.reject(error)
    );
};

export const setResponseInterceptor = () => {
    axios.interceptors.response.use(
        response => {
            return response;
        },
        err => {
            const { response: { status } } = err;

            if (status === 401) {
                store.dispatch('logoutRequest');
            }

            throw err.response;
        }
    );
};