<template>
  <div class="form-field">
    <input
      v-bind="$attrs"
      class="form-field__input"
      :class="{'form-field__input-required': isRequired && !$attrs.value}"
      placeholder=" "
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <div class="form-field__pocket" :class="pocketSizeClass"></div>
    <label :for="$attrs.id" class="form-field__label-pocket"><slot></slot></label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  
  props: {
    modelValue: [String, Number],
    isRequired: Boolean,
    pocketSize: String,
  },

  data() {
    return {
      pocketSizeClass: '',
    };
  },

  mounted() {
    if (this.pocketSize) {
      this.pocketSizeClass = `form-field__pocket-${this.pocketSize}`;
    }
  },
};
</script>
