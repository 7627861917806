<template>
  <div>
    <h1 class="admin-panel__header">Client Accounts</h1>

    <list-select header="Clients">
    </list-select>
  </div>
</template>

<script>
import ListSelect from '@/components/ListSelect.vue'

export default {
  components: { ListSelect },

}
</script>

<style>

</style>