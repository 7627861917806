export function isIndexSelected(index, referenceIndex) {
  if (index == referenceIndex) {
    return [
      'border-black',
      'border-2',
      'p-1' 
    ];
  }
}

export function formatRole(role) {
  if (role.includes('mexcellent_')) {
    role = role.replace('mexcellent_', '');
  }

  return role.charAt(0).toUpperCase() + role.slice(1);
}