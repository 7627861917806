<template>
  <div class="form">
    <pocket-input id="business-name" v-model="businessForm.name" :isRequired="true" pocketSize="30">
      Business Name
    </pocket-input>

    <button 
      class="app-button app-button--primary -mt-2 w-full mb-8" 
      @click="$emit('create', businessForm.data)"
      v-if="!v$.businessForm.$invalid && businessForm.isDirty"
    >
      Save Bussiness
    </button>

    <div class="lg:ml-4">

      <list-select 
        header="Services" 
        v-model="service" 
        v-model:options="businessForm.services"
        v-model:optionIndex="serviceEditIndex"
      >
        <template #option="{ option }">
          <div>
            <span class="font-semibold">{{ option.name }}</span>

            <div class="flex flex-col text-sm mt-2">
              <div>
                <span v-for="(category, index) in option.categories" :key="index">{{ category }}{{ index + 1 == option.categories.length ? '' : ', ' }}</span>
              </div>

              <span class="mt-1">{{ option.locations.length > 1 ? `${option.locations.length} Locations` : '1 Location' }}</span>
            </div>
          </div>
        </template>
      </list-select>

      <button class="font-medium" v-if="!showService && !service" @click="showService = true">Add Service +</button>

      <div v-if="service && !showService || showService">
        <div class="flex flex-col">
          <div class="flex">
            <h1 class="form__sub-header">
              Add Service
            </h1>
            <button class="text-sm font-medium ml-4 mb-4" @click="hideService">Hide Service</button>
          </div>
        </div>

        <service-form id="service-form" ref="serviceForm" v-model="service" @create="service => saveService(service)" />
      </div>
    </div>
  </div>
</template>

<script>
import PocketInput from './PocketInput.vue';
import ServiceForm from './ServiceForm.vue';
import ListSelect from './ListSelect.vue';

import Form from '@/utils/classes/Form';
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { isIndexSelected } from '@/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  components: { PocketInput, ServiceForm, ListSelect },

  props: {
    modelValue: Object
  },

  emits: ['create'],

  computed: {
    ...mapGetters(['currentUser'])
  },

  watch: {
    modelValue: {
      handler(business)  {
        if (business && !this.businessForm.isDirty) {
          this.businessForm.update(business);
        }
      },
      immediate: true
    },
    'businessForm.services': function() {
      if (this.serviceEditIndex != null) {
        this.hideService();
      }
    },
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      businessForm: new Form({
        created_by: null,
        name: null,
        services: [],
      }),
      service: null,
      serviceEditIndex: null,
      showService: false,
    }
  },

  mounted() {
    this.businessForm.update({ created_by: this.currentUser.id });
  },

  methods: {
    saveService(service) {
      if (this.serviceEditIndex != null) {
        this.businessForm.services[this.serviceEditIndex] = service;
      } else {
        this.businessForm.services.push(service);
      }
      
      this.hideService();
    },
    
    hideService() {
      this.service = null;
      this.serviceEditIndex = null;
      this.showService = false; 
    },

    resetForm() {
      this.businessForm.reset();
    },
    
    isIndexSelected
  },

  validations() {
    return {
      businessForm: {
        name: { required },
        services: { 
          required, 
          minLength: minLength(1),
        },
      },
    }
  }
}
</script>

<style>

</style>