import Axios from "axios";
import { basicCatch } from "./helpers";

const state = {
};

export const getters = {
};

export const actions = {
  // ROUTES
  // POST
  login({ dispatch }, credentials) {
    return new Promise((res, rej) => {
      Axios.post('/users/authenticate', credentials)
        .then(({ data }) => {
          const user = data.data.user;

          dispatch("setCurrentUser", user);
          res(user);
        })
        .catch(err => {
          basicCatch(err, "Something went wrong while trying to authenticate user.");
          rej();
        })
    })
  },

  logout({ dispatch }) {
    return new Promise((res, rej) => {
      Axios.post('/users/logout')
        .then(() => {
          dispatch('logoutRequest', null);
          res();
        })
        .catch(err => {
          basicCatch(err, 'Something went wrong while trying to logout');
          rej();
        });
    })
  },

  createUser({ dispatch }, user) {
    return new Promise((res, rej) => {
      Axios.post("/users/create", user)
        .then(({ data }) => {
          dispatch("pushNotifications", { text: "User created successfully!", type: "success" });
          res(data.data.user);
        })
        .catch(err => {
          basicCatch(err, "Something went wrong while trying to create user.");
          rej();
        })
    })
  },

  updateUser({ dispatch }, { id, update }) {
    return new Promise((res, rej) => {
      Axios.post(`/users/update/${id}`, { update })
        .then(({ data }) => {
          dispatch("pushNotifications", { text: "User updated successfully!", type: "success" });
          res(data.data.user);
        })
        .catch(err => {
          basicCatch(err, 'Something went wrong while trying to update user.');
          rej();
        });
    })
  },

  createBusinesses({ dispatch }, { ownerId, businesses }) {
    return new Promise((res, rej) => {
      Axios.post("/businesses/create", { owner_id: ownerId, businesses })
        .then(({ data }) => {
          dispatch("pushNotifications", { text: "Created users business(es) successfully!", type: "success" });
          res(data.data.businesses);
        })
        .catch(err => {
          basicCatch(err, "Something went wrong while trying to create businesses.");
          rej();
        })
    })
  },

  // GET
  getCsrfCookie() {
    return new Promise((res, rej) => {
      Axios.get(`${process.env.VUE_APP_API_URL}/sanctum/csrf-cookie`)
        .then(response => {
          res();
        })
        .catch(err => {
          basicCatch(err);
          rej();
        })
    })
  },

  getCategories({ dispatch }) {
    return new Promise((res, rej) => {
      Axios.get("/categories", {params: { without_parents: true }})
        .then(({ data }) => {
          const categories = data.data.categories;

          dispatch('setCategories', categories);
          res(categories);
        })
        .catch(err => {
          basicCatch(err, 'Something went wrong while trying to load categories.');
          rej();
        });
    })
  },

  getRoles({ dispatch }) {
    return new Promise((res, rej) => {
      Axios.get("/users/roles")
        .then(({ data }) => {
          const roles = data.data.roles;

          dispatch("setRoles", roles)
          res(roles)
        })
        .catch(err => {
          basicCatch(err, "Something went wrong while trying to get roles.");
          rej();
        });
    })
  },

  getStaff({ dispatch }) {
    return new Promise((res, rej) => {
      Axios.get("/users/staff")
        .then(({ data }) => {
          const staff = data.data.staff;

          dispatch("setStaff", staff);
          res(staff);
        })
        .catch(err => {
          basicCatch(err, 'Something went wrong while tring to fetch staff.');
          rej();
        })
    })
  },
};

export const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};