<template>
  <div>
    <div class="absolute top-0 mt-7" @click="showNav = true">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
      </svg>
    </div>

    <div>
      <transition name="fade-background">
        <div class="nav-background" v-if="showNav"></div>
      </transition>
      <transition name="slide-in-right">
        <div class="admin-panel__aside" v-if="showNav">
          <div class="absolute top-0 right-0 -mr-8 mt-4" @click="showNav = false">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" viewBox="0 0 20 20" fill="white">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </div>

          <div
            class="mb-6 text-xs leading-4 font-medium text-gray-500 uppercase"
            v-if="userIsAdmin"
          >
            Navigation
          </div>

          <span v-if="userIsAdmin" @click="showNav = false">
            <router-link
              :to="{ name: 'admin.staff' }"
              class="block mb-4"
            >Staff</router-link>
            <router-link
              :to="{ name: 'form' }"
              class="block mb-4"
            >Business Form</router-link>
            <!-- <router-link
              :to="{ name: 'admin.clients' }"
              class="block mb-4"
            >Clients</router-link> -->
          </span>

          <button
            class="absolute bottom-0 mb-8 font-medium text-brand-red"
            @click="showNav = false; $store.dispatch('logout');"
          >Logout</button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['userRole']),

    userIsAdmin() {
      return this.userRole.name == 'admin';
    }
  },

  data() {
    return {
      showNav: false
    }
  }
}
</script>

<style>

</style>