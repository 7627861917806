<template>
  <div @click="selectedUser = null; newUser = false;">
    <h1 class="admin-panel__header">Staff Accounts</h1>

    <button class="font-medium mt-2 mb-2" v-if="!newUser && !selectedUser" @click.stop="newUser = true">
      Add Account +
    </button>

    <list-select 
      v-model="selectedUser"
      :options="staff"
      :hideDelete="true"
      class="mx-2"
      @click.stop="newUser = false"
    >
      <template #option="{ option }">
        <div>
          <span class="font-semibold">{{ option.full_name }}</span>

          <div class="flex flex-col text-sm mt-2">
            {{ formatRole(option.user_role.name) }}
          </div>
        </div>
      </template>
    </list-select>

    <transition name="slide-in-bottom">
      <div 
        class="absolute bottom-0 left-0 right-0 h-2/3 bg-gray-300 rounded-t-3xl overflow-y-auto"
        v-if="selectedUser || newUser"
        @click.stop
      >
        <div class="relative">
          <div class="mt-2">
            <span class="absolute top-0 right-0 mt-4 mr-4 text-blue-500 text-sm font-semibold cursor-pointer" @click="hideUser">Close</span>
            <h1 class="text-2xl font-bold text-center">{{ newUser ? 'Create User' : `Edit ${selectedUser.full_name}`}}</h1>
          </div>
          <user-form 
            v-model="selectedUser" 
            :edit="newUser && !selectedUser ? false : true" 
            :hidePassword="selectedUser && currentUser.id != selectedUser.id"
            :showRoles="true"
            :staffOnlyRoles="true"
            @create="user => handleUser(user)"
            class="mt-6 mx-4"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ListSelect from '@/components/ListSelect.vue';
import UserForm from '@/components/UserForm.vue';

import { formatRole } from '@/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  components: { ListSelect, UserForm },

  computed: {
    ...mapGetters(['staff', 'currentUser']),
  },

  watch: {
    selectedUser: {
      handler(user) {
        if (user?.user_role.name == 'admin' && user.id != this.currentUser.id) {
          this.$toast.warning("Cannot edit other admin accounts.");
          this.selectedUser = null;
        }
      },
      flush: 'post'
    }
  },

  data() {
    return {
      selectedUser: null,
      newUser: false,
    }
  },

  mounted() {
    this.getStaff();
    this.$store.dispatch("getRoles");
  },

  methods: {
    getStaff() {
      this.$store.dispatch("getStaff");
    },

    handleUser(user) {
      if (this.newUser) {
        return this.createUser(user);
      }

      return this.updateUser(user);
    },

    createUser(user) {
      this.$store.dispatch('createUser', user)
        .then(() => {
          this.afterRequest();
        });
    },

    updateUser(user) {
      this.$store.dispatch('updateUser', { id: this.selectedUser.id, update: user})
        .then(() => {
          this.afterRequest();
        });
    },

    afterRequest() {
      this.getStaff();
      this.hideUser();
    },

    hideUser() {
      this.selectedUser = null;
      this.newUser = false;
    },

    formatRole
  }
}
</script>

<style>

</style>