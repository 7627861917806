<template>
  <div class="mx-16 mt-12">
    <div v-if="!ownerId">
      <h1 class="form__header">
        Owner Form
      </h1>
      <user-form ref="userForm" @create="user => createOwner(user)" role="owner" />
    </div>

    <div>
      <h1 class="form__header">
        Business Form
      </h1>

      <list-select
        header="Businesses"
        v-model="business"
        v-model:options="businesses"
        v-model:optionIndex="businessEditIndex"
      >
        <template #option="{ option }">
          <div>
            <span class="font-semibold">{{ option.name }}</span>

            <div class="flex flex-col text-sm mt-2">
              <span class="mt-1">{{ option.services.length > 1 ? `${option.services.length} Services` : '1 Service' }}</span>
            </div>
          </div>
        </template>
      </list-select>

      <div>
        <button class="font-medium mb-4" v-if="!business && !showBusiness" @click="showBusiness = true">Add Business +</button>

        <div v-if="business && !showBusiness || showBusiness">
          <div class="-mb-6">
            <div class="flex">
              <h1 class="form__sub-header">
                Add Business
              </h1>
              <button class="text-sm font-medium ml-4 mb-4" @click="hideBusiness">Hide Business</button>
            </div>
          </div>

          <business-form ref="businessForm" class="mt-6" v-model="business" @create="saveBusiness" />
        </div>
      </div>

      <button 
        class="app-button app-button--primary mt-4 w-full" 
        @click="createBusinesses"
        v-if="businesses.length > 0 && ownerId"
      >
        Create Business{{ businesses.length > 0 ? 'es' : '' }}!
      </button>
    </div>
  </div>
</template>

<script>
import UserForm from '@/components/UserForm.vue';
import BusinessForm from '@/components/BusinessForm.vue';
import ListSelect from '@/components/ListSelect.vue';

import { isIndexSelected } from '@/utils/helpers';

export default {
  components: { UserForm, BusinessForm, ListSelect },

  data() {
    return {
      ownerId: null,
      businesses: [],
      business: null,
      businessEditIndex: null,
      showBusiness: true,
    }
  },

  mounted() {
    this.$store.dispatch('getCategories');
  },

  methods: {
    createOwner(owner) {
      this.$store.dispatch("createUser", owner)
        .then(user => {
          this.ownerId = user.id;
        }); 
    },

    createBusinesses() {
      this.$store.dispatch("createBusinesses", { ownerId: this.ownerId, businesses: this.businesses })
        .then(() => {
          this.ownerId = null;
          this.businesses = [];
          this.showBusiness = true;
        });
    },

    saveBusiness(business) {
      if (this.businessEditIndex != null) {
        this.businesses[this.businessEditIndex] = business;
      } else {
        this.businesses.push(business);
      }

      this.hideBusiness()
    },

    hideBusiness() {
      this.showBusiness = false;
      this.business = null;
      this.businessEditIndex = null;
    },

    isIndexSelected
  },
};
</script>
