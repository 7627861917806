<template>
  <div>
    <div class="form">
      <pocket-input id="service-name" type="text" v-model="serviceForm.name" :isRequired="true" pocketSize="28">
        Service Name
      </pocket-input>

      <div class="form-field">
        <textarea class="form-field__input form-field__input-required" id="description" v-model="serviceForm.description" cols="30" rows="10" placeholder=" "></textarea>
        <div class="form-field__pocket form-field__pocket-6"></div>
        <label for="description" class="form-field__label-pocket">Description</label>
      </div>

      <div>
        <div class="tag-pill__container">
          <div class="tag-pill" v-for="(value, index) in serviceForm.categories" :key="index" @click="serviceForm.categories.splice(index, 1)">{{ value }}</div>
        </div>
      </div>

      <div 
        class="border-2 rounded" 
        :class="{ 
          'border-brand-red': serviceForm.categories.length == 0, 
          'border-brand-green': serviceForm.categories.length > 0, 
          'mt-2': serviceForm.categories.length > 0  
          }
        "
      >
        <multiselect
          placeholder="Search Categories..."
          :options="listOfCategoryNames"
          :options-limit="100"
          :max="5"
          :multiple="true"
          :hide-selected="true"
          v-model="serviceForm.categories"
        >
          <template #selection="">
            <div class="hidden"></div>
          </template>
          <template #option="{ option, search, index }">
            <div class="">
              <div class="flex flex-col my-2 ml-2 font-medium" :key="index" :innerHTML="boldSearchCharacters(option, search)">
              </div>
            </div>
          </template>
        </multiselect>
      </div>

      <button 
        class="app-button app-button--primary mt-2 w-full" 
        @click="$emit('create', serviceForm.data)"
        v-if="!v$.serviceForm.$invalid && serviceForm.isDirty"
      >
        Save Service
      </button>
    </div>

    <div class="lg:ml-8 mt-4">

      <list-select 
        header="Locations" 
        v-model="location" 
        v-model:options="serviceForm.locations" 
        v-model:optionIndex="locationEditIndex"
      >
        <template #option="{ option }">
          <div>
            <span class="font-semibold">{{ option.address }}</span>

            <div class="flex flex-col text-sm mt-2">
              {{ option.city }} {{ option.state }}, {{ option.country }} {{ option.zip }}
            </div>
          </div>
        </template>
      </list-select>

      <button class="font-medium" v-if="!showLocation && !location" @click="showLocation = true">Add Location +</button>

      <div v-if="location && !showLocation || showLocation">
        <div class="flex">
          <h1 class="form__sub-header">
            Add Location
          </h1>
          <button class="text-sm font-medium ml-4 mb-4" @click="hideLocation">Hide Location</button>
        </div>

        <location-form ref="locationForm" v-model="location" @create="location => saveLocation(location)" />
      </div>

    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import PocketInput from './PocketInput.vue';
import LocationForm from './LocationForm.vue';
import ListSelect from './ListSelect.vue';

import { mapGetters } from 'vuex';
import Form from '@/utils/classes/Form';
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'

export default {
  components: { PocketInput, Multiselect, LocationForm , ListSelect, },

  props: {
    modelValue: Object
  },

  emits: ['create'],

  setup () {
    return { v$: useVuelidate() }
  },
  
  computed: {
    ...mapGetters(['categories']),

    listOfCategoryNames() {
      return this.categories.map(c => c.name)
    }
  },

  watch: {
    modelValue: {
      handler(service) {
        if (service && !this.serviceForm.isDirty) {
          this.serviceForm.update(service)
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      serviceForm: new Form({
        name: null,
        description: null,
        categories: [],
        locations: [],
      }),
      location: null,
      locationEditIndex: null,
      showLocation: false,
    }
  },

  methods: {
    saveLocation(location) {
      if (this.locationEditIndex != null) {
        this.serviceForm.locations[this.locationEditIndex] = location;
      } else {
        this.serviceForm.locations.push(location);
      }

      this.hideLocation();
    },

    hideLocation() {
      this.location = null;
      this.locationEditIndex = null;
      this.showLocation = false;
    },

    boldSearchCharacters(option, search) {
      if (search) {
        let newOption = option.toLowerCase().replace(RegExp(search.toLowerCase(), 'g'), `<span class="font-semibold">${search.toLowerCase()}</span>`);

        if (newOption.charAt(0) == '<') {
          let capIndex = newOption.indexOf('>') + 1;
          newOption = newOption.substring(0, capIndex) + newOption.charAt(capIndex).toUpperCase() + newOption.substring(capIndex + 1);
        } else {
          newOption = newOption.charAt(0).toUpperCase() + newOption.slice(1);
        }
        
        return `<span>` + newOption + `</span>`;
      }

      return option;
    },

    resetForm() {
      this.serviceForm.reset();
    },
  },

  validations() {
    return {
      serviceForm: {
        name: { required },
        description: { required },
        categories: { required, minLength: minLength(1), maxLength: maxLength(5) },
        locations: { required, minLength: minLength(1) }
      },
    }
  }
}
</script>

<style>
@import '@/assets/scss/components/service-form.scss';
</style>