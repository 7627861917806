<template>
  <div class="form flex flex-col">
    <pocket-input type="text" id="first-name" v-model="userForm.first_name" :isRequired="true">
      First Name
    </pocket-input>
    <pocket-input type="text" id="last-name" v-model="userForm.last_name" :isRequired="true">
      Last Name
    </pocket-input>

    <div class="form-field">
      <vue-tel-input
        v-model="phone"
        class="leading-tight py-2 pl-1 pr-2 !border-2 !border-green-700 bg-white"
        :class="{ '!border-brand-red': v$.phone.$invalid }"
        mode="international"
        :default-country="'MX'" 
        :preferred-countries="['MX', 'US', 'CA', 'GB', 'FR']"
        :input-options="{ 
          placeholder: 'Phone', 
          styleClasses: 'font-semibold text-lg',
          autocomplete: 'none'
        }"
      ></vue-tel-input>
    </div>
    
    <pocket-input type="email" id="email" v-model="userForm.email" :isRequired="true" pocketSize="14">
      E-mail
    </pocket-input>

    <div v-if="!hidePassword">
      <tool-tip>
        <pocket-input 
          type="password" 
          id="password" 
          v-model="userForm.password" 
          :isRequired="!edit" 
          pocketSize="20"
        >
          Password
        </pocket-input>

        <template v-slot:message>
          <div>
            <span class="font-semibold">Password Must Have:</span> <br>
            <ol class="mt-2 list-disc list-inside">
              <li class="text-red-500" :class="{ 'text-green-500': passwordValidations.hasEightCharacters }">Minumum of 8 characters.</li>
              <li class="text-red-500" :class="{ 'text-green-500': passwordValidations.hasUppercase }">One uppercase character</li>
              <li class="text-red-500" :class="{ 'text-green-500': passwordValidations.hasLowercase }">One lowercase character</li>
              <li class="text-red-500" :class="{ 'text-green-500': passwordValidations.hasSpecialCharacter }">One special character</li>
              <li class="text-red-500" :class="{ 'text-green-500': passwordValidations.hasNumber }">One number</li>
            </ol>
          </div>
        </template>
      </tool-tip>
      <pocket-input type="password" id="password-confirmation" v-model="passwordConfirm" :isRequired="!edit" pocketSize="44">
        Password Confirmation
      </pocket-input>
    </div>

    <div class="form-field" v-if="showRoles">
      <select 
        v-model="userForm.role" 
        id="role-select" 
        class="form-field__input bg-white font-semibold"
        :class="{ 'border-brand-red': !userForm.role, 'border-brand-green': userForm.role }"
      >
        <option :value="null" v-if="!userForm.role">Select Role...</option>
        <option 
          v-for="(role, index) in displayRoles" 
          :value="role.name" 
          :key="index"
          class="font-semibold"
        >
          {{ formatRole(role.name) }}
        </option>
      </select>
      <div class="form-field__pocket form-field__pocket-11"></div>
      <label for="role-select" class="form-field__label-pocket">Role</label>
    </div>

    <button 
        class="app-button app-button--primary -mt-2 w-full" 
        @click="create"
        v-if="!v$.userForm.$invalid && !v$.phone.$invalid && userForm.isDirty"
      >
        {{ edit ? 'Update' : 'Create' }} User!
      </button>
  </div>
</template>

<script>
import PocketInput from './PocketInput.vue'
import ToolTip from './ToolTip.vue';

import Form from '@/utils/classes/Form'
import useVuelidate from '@vuelidate/core'
import { required, email, requiredIf, minLength } from '@vuelidate/validators'
import { mapGetters } from 'vuex';
import { formatRole } from '@/utils/helpers';

export default {
  components: { PocketInput, ToolTip },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    role: String,
    showRoles: {
      type: Boolean,
      default: () => false
    },
    hidePassword: {
      type: Boolean,
      default: () => false
    },
    staffOnlyRoles: {
      type: Boolean,
      default: () => false
    },
    edit: {
      type: Boolean,
      default: () => false
    }
  },

  emits: ['create'],

  setup () {
    return { v$: useVuelidate() }
  },

  computed: {
    ...mapGetters(['roles', 'staffRoles']),

    displayRoles() {
      return this.staffOnlyRoles ? this.staffRoles : this.roles;
    },
    
    passwordsMatch() {
      return this.userForm.password == this.passwordConfirm;
    },

    passwordValidations() {
      return {
        hasEightCharacters: this.userForm.password?.length >= 8,
        hasUppercase: /[A-Z]/.test(this.userForm.password),
        hasLowercase: this.userForm.password && /[a-z]/.test(this.userForm.password),
        hasSpecialCharacter: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.userForm.password),
        hasNumber: /[\d]/.test(this.userForm.password)
      }
    },

    passwordIsValid() {
      for (let key in this.passwordValidations) {
        if (!this.passwordValidations[key]) {
          return false;
        }
      }

      return true;
    }
  },

  watch: {
    modelValue: {
      handler(user) {
        if (user && !this.userForm.isDirty || this.edit) {
          this.phone = user.phone;
          user.role = user.user_role.name;
          this.userForm.update(user);
          this.userForm.password = null,
          this.passwordConfirm = null;
        }
      },
      immediate: true
    },
  },

  data() {
    return {
      userForm: new Form({
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        password: null,
        role: null
      }),
      passwordConfirm: null,
      phone: null,
    }
  },

  mounted() {
    if (this.role) {
      this.userForm.role = this.role;
    }
  },

  methods: {
    create() {
      this.userForm.phone = this.phone.replaceAll(' ', '');
      this.$emit('create', this.edit ? this.userForm.dirtyData : this.userForm.data)
    },

    resetForm() {
      this.userForm.reset();
    },

    formatRole
  },

  validations() {
    const passwordNeeded = this.edit && !this.hidePassword && this.userForm.password || !this.edit;
    const passwordIsValid = () => {
      if (passwordNeeded) {
        return this.passwordIsValid;
      }

      return true;
    }
    const passwordsMatch = () => {
      if (passwordNeeded) {
        return this.passwordsMatch;
      }

      return true;
    }

    return {
      userForm: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        password: { required: requiredIf(() => passwordNeeded), passwordIsValid, passwordsMatch },
        role: { required: requiredIf(() => this.showRoles) }
      },
      phone: { required, minLength: minLength(10) },
    }
  }
}
</script>