import * as types from "@/store/mutations";

const state = {
  categories: null
};

export const getters = {
  categories: state => state.categories,
};

export const actions = {
  setCategories({ commit }, categories) {
    commit(types.SET_CATEGORIES, categories);
  }
};

export const mutations = {
  [types.SET_CATEGORIES](state, categories) {
    state.categories = categories;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};