<template>
  <div class="relative flex flex-col items-center group">
    <slot />

    <div class="absolute bottom-0 left-0 flex-col items-center hidden group-focus-within:flex" style="margin-bottom: 5.25rem;">
      <span 
        class="relative z-10 p-2 text-xs leading-none text-black whitespace-no-wrap bg-gray-200 shadow-lg rounded-md"
      >
        <slot name="message" />
      </span>
      <div class="w-3 h-3 -mt-2 rotate-45 bg-gray-200"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String
  }
}
</script>

<style>

</style>