<template>
  <div>
    <div class="admin-panel">
      <div class="admin-panel__main mt-8">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showNav: false,
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/components/admin-panel.scss";
</style>