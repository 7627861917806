import { createRouter, createWebHistory } from 'vue-router';
import BusinessFormView from '@/views/BusinessFormView.vue';
import LoginView from '@/views/LoginView.vue';
import AdminPanel from '@/views/AdminPanel.vue';
import AdminPanelStaff from '@/views/AdminPanelStaff.vue';
import AdminPanelClients from '@/views/AdminPanelClients.vue';
import store from '@/store';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/form',
    name: 'form',
    component: BusinessFormView,
  },
  {
    path: '/admin-panel',
    name: 'admin',
    component: AdminPanel,
    redirect: { name: 'admin.staff' },
    children: [
      {
        path: 'staff',
        name: 'admin.staff',
        meta: { requiresAdmin: true },
        component: AdminPanelStaff
      },
      {
        path: 'clients',
        name: 'admin.clients',
        meta: { requiresAdmin: true },
        component: AdminPanelClients
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const getters = store.getters;
  const loggedIn = !!getters.currentUser;

  if (!loggedIn && to.name != 'login') {
    return next({ name: 'login' });
  }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    switch (getters.userRole.name) {
      case 'admin':
        return next();
      case 'mexcellent_employee':
        return next({ name: 'form' });
    }
  }

  return next();
})

export default router;
