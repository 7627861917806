import router from "@/router";
import * as types from "@/store/mutations";

const state = {
  currentUser: null,
  userRole: null,
  userPermissions: null,
  token: null,
  roles: [],
  staff: [],
};

export const getters = {
  currentUser: state => state.currentUser,
  userRole: state => state.userRole,
  userPermissions: state => state.userPermissions,
  token: state => state.token,
  roles: state => state.roles,
  staffRoles: state => state.roles.filter(r => ['admin', 'mexcellent_employee'].includes(r.name)),
  staff: state => state.staff,
};

export const actions = {
  setCurrentUser({ commit }, user) {
    commit(types.SET_CURRENT_USER, user);
  },
  
  setRoles({ commit }, roles) {
    commit(types.SET_ROLES, roles);
  },
  
  setStaff({ commit }, staff) {
    commit(types.SET_STAFF, staff);
  },

  logoutRequest({ commit }) {
    commit(types.LOGOUT);
    router.push({ name: 'login' });
  }
};

export const mutations = {
  [types.SET_CURRENT_USER](state, user) {
    state.currentUser = user;
    state.userRole = user.user_role;
    state.userPermissions = user.user_permissions;
    state.token = `Bearer ${user.token}`;
  },

  [types.SET_ROLES](state, roles) {
    state.roles = roles;
  },

  [types.SET_STAFF](state, staff) {
    state.staff = staff;
  },

  [types.LOGOUT](state) {
    state.currentUser = null;
    state.userRole = null;
    state.userPermissions = null;
    state.token = null;
    state.roles = [];
    state.staff = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};