import { createApp } from 'vue';
import App from './App.vue';
import './index.css';
import router from './router';
import store from './store';
import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { configureApi } from './store/api';

const app = createApp(App)
configureApi();

app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    position: 'top-center',
    maxToasts: 3,
    newestOnTop: true
});
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    }
});
app.use(VueTelInput);
app.use(store);
app.use(router);
app.mount('#app');

app.config.globalProperties.$toast = useToast();