class Form {
  initialData = {};
  originalData = {};

  constructor(fields) {
    this.initialData = { ...fields };
    this.originalData = { ...fields };
    this._assignFields();
  }

  // To update fields and not originalData
  fill(data) {
    for (let field in data) {
      if (field in this.originalData) {
        this[field] = data[field];
      }
    }
  }

  // Update fields and originalData
  update(data) {
    for (let field in data) {
      if (field in this.initialData) {
        this.originalData[field] = data[field];

        if (Array.isArray(data[field]) || typeof data[field] == "object") {
          data[field] = JSON.parse(JSON.stringify(data[field]));
        }

        this[field] = data[field];
      }
    }
  }

  reset() {
    this._assignFields();
  }

  initialState() {
    this.originalData = { ...this.initialData };
    this._assignFields();
  }

  _assignFields() {
    for (let field in this.originalData) {
      let fieldData = this.originalData[field];
      const fieldIsArray = Array.isArray(fieldData);

      if (typeof fieldData == "object" && !fieldIsArray) {
        fieldData = JSON.parse(JSON.stringify(fieldData));
      } else if (fieldIsArray) {
        fieldData = [...fieldData];
      }

      this[field] = fieldData;
    }
  }

  isKeyDirty(key) {
    return this.dirtyKeys.includes(key);
  }

  isNestedKeyDirty(nestName, index, key) {
    try {
      return Object.keys(this.dirtyData[nestName][index]).includes(key);
    } catch {
      return false;
    }
  }

  get data() {
    let data = {};

    for (let field in this.originalData) {
      data[field] = this[field];
    }

    return data;
  }

  get isDirty() {
    return Object.keys(this.dirtyData).length > 0;
  }

  get dirtyData() {
    let dirtyData = {};

    for (let field in this.originalData) {
      const originalField = this.originalData[field];
      const currentField = this[field];

      if (Array.isArray(originalField)) {
        if (currentField.length != originalField.length) {
          dirtyData[field] = currentField;
        } else if (field == "preference_options") {
          originalField.forEach((option, index) => {
            const currentOption = currentField[index];

            for (let f in option) {
              if (option[f] != currentOption[f]) {
                if (Array.isArray(dirtyData[field])) {
                  dirtyData[field].push(currentOption);
                  continue;
                }

                dirtyData[field] = [currentOption];
                continue;
              }
            }
          });
        }

        continue;
      }

      if (currentField != originalField) {
        dirtyData[field] = currentField;
      }
    }

    return dirtyData;
  }

  get dirtyKeys() {
    return Object.keys(this.dirtyData);
  }

  get allKeysAreDirty() {
    return this.dirtyKeys.length == Object.keys(this.originalData).length;
  }
}

module.exports = Form;