<template>
  <div v-if="options && options.length > 0">
    <h1 class="form__sub-header">
      {{ header }}
    </h1>

    <ul class="mb-4">
      <li 
        v-for="(option, index) in options" 
        :key="index"
        class="mb-4"
        :class="isIndexSelected(index, editIndex)"
      >
        <div class="flex flex-row justify-between" @click="editOption(option, index)">
          <slot name="option" :option="option" />
          
          <div class="self-center" @click.stop="removeOption(index)" v-if="!hideDelete">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
      </li> 
    </ul>
  </div>
</template>

<script>
import { isIndexSelected } from '@/utils/helpers';

export default {
  props: {
    modelValue: Object,
    header: String,
    options: Array,
    optionIndex: Number,
    hideDelete: {
      type: Boolean,
      default: () => false
    }
  },

  watch: {
    modelValue: {
      handler(option) {
        if (option == null) {
          this.editIndex = null;
        }
      },
      immediate: true
    },

    optionIndex(index) {
      if (index != this.editIndex) {
        this.editIndex = index;
      }
    }
  },

  data() {
    return {
      editIndex: null,
    }
  },

  methods: {
    editOption(option, index) {
      if (index == this.editIndex) {
        return this.$emit('update:modelValue', null);
      }

      this.editIndex = index;
      this.$emit('update:modelValue', option);
      this.$emit('update:optionIndex', this.editIndex);
    },

    removeOption(index) {
      if (index == this.editIndex) {
        this.$emit('update:modelValue', null);
      }

      let options = [...this.options];
      options.splice(index, 1);

      this.$emit('update:options', options);
    },

    isIndexSelected
  }
}
</script>

<style>

</style>