<template>
  <div>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link 
      href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" 
      rel="stylesheet"
    >

    <main-header />

    <div class="container mx-auto">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';

import { mapGetters } from 'vuex';

export default {
  components: { MainHeader },

  computed: {
    ...mapGetters(['notifications']),
  },

  watch: {
    notifications(notifications) {
      if (notifications.length > 0) {
        this.$toast.clear();

        for (let notification of notifications) {
          this.$toast(notification.text, { type: notification.type });
        }
      }
    },
  },
}
</script>

<style>
@import '@/assets/scss/app.scss';
</style>
