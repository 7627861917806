<template>
  <div class="form" v-if="locationForm">
    <pocket-input type="text" id="address" autocomplete="none" v-model="locationForm.address" :isRequired="true" pocketSize="17">
      Address
    </pocket-input>
    
    <pocket-input type="text" id="city" v-model="locationForm.city" :isRequired="true" pocketSize="10">
      City
    </pocket-input>

    <pocket-input type="text" id="state" v-model="locationForm.state" :isRequired="true" pocketSize="14">
      State
    </pocket-input>

    <pocket-input type="text" id="country" v-model="locationForm.country" :isRequired="true" pocketSize="17">
      Country
    </pocket-input>

    <pocket-input type="text" id="zipcode" v-model="locationForm.zip" :isRequired="true" pocketSize="30">
      Zip/Postal Code
    </pocket-input>

    <div class="form-field">
      <vue-tel-input
        v-model="phone"
        class="leading-tight py-2 pl-1 pr-2 !border-2 !border-green-700"
        :class="{ '!border-brand-red': v$.locationForm.phone.$invalid }"
        mode="international"
        :default-country="'MX'" 
        :preferred-countries="['MX', 'US', 'CA', 'UK', 'FR']"
        :input-options="{ 
          placeholder: 'Phone', 
          styleClasses: 'font-semibold text-lg',
          autocomplete: 'none'
        }"
      ></vue-tel-input>
    </div>

    <label for="google-map" class="form-field__label">
      Location Geofence <br>

      <span class="text-xs form-field__label form-field__label-required" v-if="locationForm.geofence.length <= 2">Required</span>
    </label>
    <GMapMap
      map-type-id="satellite"
      style="width: 100%; height: 500px"
      :center="mapCenter"
      :zoom="18"
      :options="{
        streetViewControl: false,
        rotateControl: false
      }"
      :tilt="0"
      id="google-map"
      @click="addPointToPolygon"
    >
      <GMapPolygon
        :editable="true"
        :paths="locationForm.geofence"
        :options="{
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        }"
        :key="locationForm.geofence.length"
        @click="addPointToPolygon"
      />
    </GMapMap>

    <button 
      class="app-button app-button--primary mt-2 w-full" 
      @click="$emit('create', locationForm.data)"
      v-if="!v$.locationForm.$invalid && locationForm.isDirty"
    >
      Save Location
    </button>
  </div>
</template>

<script>
import PocketInput from './PocketInput.vue';

import Form from '@/utils/classes/Form';
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'

export default {
  components: { PocketInput },

  props: {
    modelValue: Object
  },

  emits: ['create'],

  setup () {
    return { v$: useVuelidate() }
  },

  watch: {
    modelValue: {
      handler(location) {
        if (location && !this.locationForm.isDirty) {
          this.mapCenter = this.modelValue.geofence[0];
          this.locationForm.update(location);
        }
      },
      immediate: true
    },

    phone(val) {
      if (val.includes(" ")) {
        val = val.replaceAll(' ', '');
      }

      this.locationForm.phone = val;
    }
  },

  data() {
    return {
      locationForm: new Form({
        address: null,
        city: null,
        state: null,
        country: null,
        zip: null,
        phone: null,
        geofence: [],
      }),
      mapCenter: {lat: 22.890533, lng: -109.916740},
      autocomplete: null,
      phone: null,
    }
  },

  mounted() {
    // navigator.geolocation.getCurrentPosition(position => {
    //   this.currentLocation = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude
    //   };
    // });

    const options = {
      bounds: {
        north: this.mapCenter.lat + 1,
        south: this.mapCenter.lat - 1,
        east: this.mapCenter.lng + 1,
        west: this.mapCenter.lng - 1,
      },
      componentRestrictions: { country: 'mx' }
    };

    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'), options);
    this.autocomplete.addListener("place_changed", this.addressChanged);
  },

  methods: {
    addressChanged() {
      const place = this.autocomplete.getPlace();
      const components = place.address_components;

      for (let component of components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            this.locationForm.address = `${component.long_name}`;
            break;
          }

          case 'route': {
            if (this.address) {
              this.locationForm.address += ` ${component.short_name}`;
              break;
            }
            
            this.locationForm.address = component.short_name;
          }

          case 'postal_code': {
            this.locationForm.zip = component.long_name;
            break;
          }

          case 'postal_code_suffix': {
            this.locationForm.zip = `${this.locationForm.zip}-${component.long_name}`;
            break;
          }

          case 'locality': {
            this.locationForm.city = component.long_name;
            break;
          }

          case 'administrative_area_level_1': {
            this.locationForm.state = component.short_name;
            break;
          }

          case 'country': {
            this.locationForm.country = component.long_name;
            break;
          }
        }
      }
    },

    addPointToPolygon($event) {
      const point = {
        lat: $event.latLng.lat(),
        lng: $event.latLng.lng()
      };
      
      const index = this.checkIfPointExists(point);
      if (index !== false) {
        return this.locationForm.geofence.splice(index, 1);
      }

      this.locationForm.geofence.push(point);
    },

    checkIfPointExists(point) {
      const index = this.locationForm.geofence.findIndex(p => p.lat == point.lat && p.lng == point.lng);
      if (index == -1) {
        return false
      }

      return index;
    },

    resetForm() {
      this.locationForm.reset();
    }
  },

  validations() {
    return {
      locationForm: {
        address: { required },
        city: { required },
        state: { required },
        country: { required },
        zip: { required },
        phone: { required, minLength: minLength(10) },
        geofence: { required, minLength: minLength(3) }
      }
    }
  }
}
</script>

<style>

</style>